import movaexImage from '../assets/movaexImage.png';
import starPImage from '../assets/starP.png';
import playstoreImage from '../assets/playstore.png';
import applestoreImage from '../assets/applestore.png';
import '../styles/FooterSection.css';

export default function FooterSection() {
  return (
    <div className='footer footerStyle '>
      <div className=' w-full md:w-10 lg:w-9 m-auto'>
        <div className='grid  border__bottom'>
          <div className='col-12 md:col-4 lg:col-3 xl:col-3 '>
            <div className='movaex__image'>
              <img src={movaexImage} alt='' className='_____image' />
            </div>
            <div className='apple___image my-2'>
              <a
                href='https://app.movaex.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={applestoreImage}
                  alt='apple logo'
                  className='footer__imgStyle1'
                  loading='lazy'
                />
              </a>
            </div>
            <div className='play__image'>
              <a
                href='https://play.google.com/store/apps/details?id=com.movaex.customer'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={playstoreImage}
                  alt='android logo'
                  className='footer__imgStyle1'
                  loading='lazy'
                />
              </a>
            </div>
          </div>

          <div className='col-12 md:col-3'>
            <p className='cursor-pointer paragraphStyle'>
              <a href='/' className='footer__a'>
                {' '}
                Home
              </a>
            </p>
            <p className='cursor-pointer paragraphStyle'>
              <a href='/about' className='footer__a'>
                About Us{' '}
              </a>
            </p>
            <p className='cursor-pointer paragraphStyle'>
              <a href='/contact' className='footer__a'>
                Contact Us
              </a>
            </p>
            <p className='cursor-pointer paragraphStyle'>
              <a href='/career' className='footer__a'>
                Careers
              </a>
            </p>

            <p className='cursor-pointer paragraphStyle'>
              <a href='/partners' className='footer__a'>
                partner
              </a>
            </p>

            {/* <p className='cursor-pointer paragraphStyle'>
              <a href='/ourapp' className='footer__a'>
                Our App
              </a>
            </p> */}

            {/* <p className='cursor-pointer paragraphStyle'>Terms & Conditions</p> */}
          </div>

          <div className='col-12 md:col-3'>
            <p className='cursor-pointer paragraphStyle'>
              <a href='/faq' className='footer__a'>
                {' '}
                Help & FAQ
              </a>
            </p>

            <p className='cursor-pointer paragraphStyle'>
              <a href='/terms-of-service' className='footer__a'>
                Terms of Service
              </a>
            </p>
            <p className='cursor-pointer paragraphStyle'>
              <a href='/privacy' className='footer__a'>
                Privacy policy
              </a>
            </p>
            <p className='cursor-pointer paragraphStyle'>
              <a href='/cookie-policy' className='footer__a'>
                Cookie policy
              </a>
            </p>
            <p className='cursor-pointer paragraphStyle'>
              <a href='/userdata' className='footer__a'>
                User Data
              </a>
            </p>

            {/* <p className='cursor-pointer paragraphStyle'>Terms & Conditions</p> */}
          </div>
          <div className='col-12 md:col-3'>
            <div className='footerImage footerImageStyle'>
              <img src={starPImage} alt='' className='footer__star__image' />
            </div>
            <p className='mgt'>visit our socials</p>
            <div>
              <a
                href='https://www.facebook.com/profile.php?id=61555412589425&mibextid=ZbWKwL'
                target='blank'
                className='footer__a'
              >
                <i
                  className='pi pi-facebook'
                  style={{ fontSize: '1.5rem', color: '#E44540' }}
                ></i>
              </a>

              <a
                href='https://www.instagram.com/movaexlogistics/'
                target='blank'
                className='footer__a'
              >
                <i
                  className='pi pi-instagram mx-3'
                  style={{ fontSize: '1.5rem', color: '#E44540' }}
                ></i>{' '}
              </a>
              <a
                href='https://twitter.com/movaexLogistics'
                className='footer__a'
                target='blank'
              >
                <i
                  className='pi pi-twitter'
                  style={{ fontSize: '1.5rem', color: '#E44540' }}
                ></i>
              </a>
            </div>
          </div>
        </div>

        <small className='smll'>&#169; Movaex. All right reserved</small>
      </div>
    </div>
  );
}
