import '../../styles/companyObjective.css';

export default function CompanyObjective() {
  const innovationList = [
    {
      title: 'Leadership in Innovation:',
      list1: 'Pioneer and lead the industry with innovative solutions in logistics.',
      list2: 'Continuously explore and implement cutting-edge technologies to stay ahead of the curve.',
    },
    {
      title: 'Reliability as a Cornerstone:',
      list1: 'Establish and maintain a reputation for unparalleled reliability in every aspect of our logistics services.',
      list2: 'Implement rigorous quality control measures to ensure the secure and timely delivery of all packages.',
    },
    {
      title: 'Customer Empowerment:',
      list1: 'Empower businesses and individuals by providing seamless and reliable logistics solutions.',
      list2: 'Prioritize customer needs through personalized services and a user-friendly mobile app.',
    },
    {
      title: 'Swift and Efficient Operations:',
      list1: 'Excel in speed and efficiency in handling and delivering packages.',
      list2: 'Streamline processes to provide swift and seamless operations that meet the dynamic demands of our clients.',
    },
    {
      title: 'Exceptional Customer Support:',
      list1: 'Provide 24/7 customer support to address inquiries, offer assistance, and enhance the overall customer experience.',
      list2: 'Continuously improve customer support processes based on feedback and evolving customer needs.',
    },
    {
      title: 'Transparency Through Technology:',
      list1: 'Leverage technology to provide real-time tracking and transparent communication throughout the delivery process.',
      list2: 'Ensure customers have complete visibility into the status of their shipments at every stage.',
    },
    {
      title: 'Expansion and Accessibility:',
      list1: 'Expand our service areas to better serve a growing customer base.',
      list2: 'Enhance accessibility through ongoing efforts to reach new regions and communities.',
    },
    {
      title: 'Commitment to Environmental Sustainability:',
      list1: 'Implement eco-friendly practices and explore sustainable solutions in our logistics operations.',
      list2: 'Strive to minimize the environmental impact of our services through responsible business practices.',
    },
    {
      title: 'Continuous Improvement:',
      list1: 'Foster a culture of continuous improvement within the company.',
      list2: 'Regularly assess and refine our processes to adapt to industry changes and deliver increasingly exceptional services.',
    },
  ]
  ;
  return (
    <section
      className='w-11 md:w-11 lg:w-11 xl:w-9 m-auto  '
      data-aos='fade-down'
    >
      <div className='text-center company__objective__title'>
        <h2 className='company__objective__title__h2'>Company Objectives</h2>
      </div>
      {innovationList.map((item, index) => (
        <div className=' ' key={index}>
          <h5 className='-mb-4 company__objective___paragraph__h5'>{item.title}</h5>
          <ul className='list-disc'>
            <li className='company__objective___paragraph__li'>{item.list1}</li>
            <li className='company__objective___paragraph__li'>{item.list2}</li>
          </ul>
        </div>
      ))}
    </section>
  );
}
