import manImage from '../assets/man.png';
import man1Image from '../assets/man1.png';
import womanImage from '../assets/womanImage.png';
import chefImage from '../assets/chefImage.png';
import { Divider } from 'primereact/divider';
import '../styles/ForthSection.css';
import starPImage from '../assets/starP.png';
import './forthSection';

export default function ForthSection() {
  return (
    <div
      className='w-11  mb-1 md:w-10 lg:w-11 xl:w-9
      m-auto md:mb-2'
      data-aos='fade-down'
    >
      <div className=''>
        <h2 className='headerStyle4  text-center'>
          We are Your trusted partner in seamless, on-demand delivery.
        </h2>
      </div>

      <div
        className='grid w-full  md:w-full lg:w-full xl:w-full
      m-auto '
      >
        <div
          className='col-12 md:col-4 lg:col-3 lg:col-offset-1 xl:col-3 xl:col-offset-2  mt-5'
          data-aos='zoom-in'
        >
          <div className='image -mr-5 bgf4'>
            <img src={manImage} alt='' className='imageStyle4' loading='lazy' />
          </div>
        </div>

        <div
          className='col-12 md:col-8 lg:col-7 lg:ml-1 xl:-ml-3'
          data-aos='zoom-in'
        >
          <div className='title4'>
            <h4 className='titleStyle4'> Business Owners</h4>
            <p className=' -mt-2 lg:-mt-3 paragraph4'>
              Running a successful business requires efficiency, and that's
              where Movaex comes in. We understand the unique challenges you
              face in managing your supply chain and meeting customer demands.
              With our super app, you gain access to a network of dedicated
              drivers who ensure your products reach your customers on time,
              every time. Let us handle the logistics while you focus on growing
              your business.
            </p>
          </div>
        </div>

        <Divider type='solid' />

        <div className='col-12 md:col-4  lg:col-3 xl:col-3 ' data-aos='zoom-in'>
          <div className=' bgf4'>
            <img
              src={man1Image}
              alt=''
              className='imageStyle4'
              loading='lazy'
            />
          </div>
        </div>

        <div
          className='col-12 md:col-6 lg:col-7 xl:col-7 lg:ml-0 xl:-ml-3'
          data-aos='zoom-in'
        >
          <div className='title'>
            <h4 className='titleStyle4'> Lawyers</h4>
            <p className='-mt-2 lg:-mt-3 paragraph4'>
              In the legal field, time is of the essence. Legal documents,
              contracts, and evidence need to be delivered promptly and
              securely. Movaex provides lawyers with a trusted delivery solution
              that ensures sensitive materials are handled with care and
              confidentiality. Rest easy knowing that your legal documents are
              in safe hands.
            </p>
          </div>
        </div>

        <div
          className=' hidden md:block lg:block xl:block  md:col-2 lg:col-2  xl:col-1'
          data-aos='zoom-in'
        >
          <div className=' text-center'>
            <img
              src={starPImage}
              alt=''
              className=' st__image4'
              loading='lazy'
            />
          </div>
        </div>

        <Divider type='solid' />

        <div
          className='col-12 md:col-4 lg:col-3 lg:col-offset-1 xl:col-3 xl:col-offset-2  mt-5 '
          data-aos='zoom-in'
        >
          <div className='image xs:text-center bgf4'>
            <img
              src={womanImage}
              alt=''
              className='imageStyle4'
              loading='lazy'
            />
          </div>
        </div>

        <div
          className='col-12 md:col-8 lg:col-7 lg:ml-1 xl:-ml-3 '
          data-aos='zoom-in'
        >
          <div className='title4'>
            <h4 className='titleStyle4'> Individuals</h4>
            <p className='-mt-2 lg:-mt-3 paragraph4'>
              Whether it's sending a birthday gift to a loved one, satisfying a
              food craving, or moving items from one location to another, Movaex
              simplifies the process. Our user-friendly app allows individuals
              to request deliveries with ease. Say goodbye to the hassle of
              traditional courier services and hello to a more convenient way of
              sending and receiving items.
            </p>
          </div>
        </div>

        <Divider type='solid' />

        <div
          className='col-12 md:col-4  lg:col-3 xl:col-3 mt-5'
          data-aos='zoom-in'
        >
          <div className='image4 bgf4'>
            <img
              src={chefImage}
              alt=''
              className='imageStyle4'
              loading='lazy'
            />
          </div>
        </div>

        <div
          className='col-12 md:col-8 lg:col-7 lg:ml-1 xl:-ml-3'
          data-aos='zoom-in'
        >
          <div className='title4'>
            <h4 className='titleStyle4'> Food Vendors</h4>
            <p className='-mt-2 lg:-mt-3 paragraph4'>
              Food vendors know the importance of delivering delicious meals
              while they're hot and fresh. Movaex helps you maintain the quality
              of your cuisine by offering swift and dependable delivery
              services. From food trucks to catering services, we ensure your
              culinary creations reach your customers at their tastiest.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
