import Herosection from '../components/shareCoponent/heroSection';
import movaPhoneImage from '../assets/MovaPhone.png';
import '../styles/ourApp.css';
// import { Divider } from 'primereact/divider';
export default function Ourapp() {
  return (
    <section>
      {/* hero section component starts here */}
      <Herosection title='Our App' />
      {/* hero section component ends here */}

      <div className='w-11 md:w-11 lg:w-9 xl:w-8 m-auto  '>
        <div className='grid our__app__main'>
          <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
            <article className='side__article'>
              <h6 className='side__article__h6 mb-3'>What is Movaex?</h6>
              <p className='side__article__p'>
                Movaex, is redefining the way logistics work. We are harnessing
                the power of technology to create a seamless, on-demand delivery
                experience for businesses and individuals alike
              </p>
            </article>
          </div>
          <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
            <div className='image'>
              <img src={movaPhoneImage} alt='' className='our__app__image' />
            </div>
          </div>
        </div>
        {/* <Divider />
        <div className='grid  mt-6 md:mt-6 lg:mt-5 xl:mt-6'>
          <div className='col-12 md:col-6 lg:col-6 xl:col-6 '>
            <div className='image'>
              <img src={movaPhoneImage} alt='' className='our__app__image' />
            </div>
          </div>

          <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
            <article className='side__article'>
              <h6 className='side__article__h6 mb-3 '>What is Movaex?</h6>
              <p className='side__article__p'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </article>
          </div>
        </div> */}
        {/* <Divider />
        <div className='grid  mt-6 md:mt-6 lg:mt-5 xl:mt-6'>
          <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
            <article className='side__article'>
              <h6 className='side__article__h6 mb-3'>What is Movaex?</h6>
              <p className='side__article__p'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </article>
          </div>
          <div className='col-12 md:col-6 lg:col-6 xl:col-6 '>
            <div className='image'>
              <img src={movaPhoneImage} alt='' className='our__app__image' />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}
