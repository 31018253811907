import playstoreImage from '../assets/playstore.png';
import applestoreImage from '../assets/applestore.png';
import '../styles/FirstSection.css';
import 'animate.css/animate.min.css';

export default function FirstSection() {
  return (
    <div
      className='w-11  mb-1 md:w-10 lg:w-10 xl:w-10 m-auto  md:mb-2 '
      data-aos='fade-up'
    >
      <div className='w-full sm:w-full md:w-10 lg:w-9 xl:w-8'>
        <h2 className='FirstSectionStyle1'>
          On-demand door to door delivery everyday
        </h2>
        <p className='-mt-3 FirstSectionStyle_paragraph1  '>
          Movaex, is redefining the way logistics work. We are harnessing the
          power of technology to create a seamless, on-demand delivery
          experience for businesses and individuals alike.
        </p>

        <div className=' grid  lg:-ml-2  xl:-ml-2 mt-2'>
          <div className='col-6 grid1 md:col-3 lg:col-3 xl:col-2'>
            <a
              href='https://app.movaex.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={applestoreImage}
                alt='apple logo'
                className='imgStyle__1'
                loading='lazy'
              />
            </a>
          </div>

          <div className='col-6 grid1 md:col-3 lg:col-3 xl:col-2 lg:ml-1  '>
            <a
              href='https://play.google.com/store/apps/details?id=com.movaex.customer'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={playstoreImage}
                alt='android logo'
                className='imgStyle__1'
                loading='lazy'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
