import '../styles/secondSection.css';
import womanBgImage from '../assets/womanbgimage.png';
import badgeImage from '../assets/mova.png';
import starPImage from '../assets/starP.png';
import circleVectorImage from '../assets/circleVector.svg';
import React, { useEffect, useState, useMemo } from 'react';

export default function SecondSection() {
  const titles = useMemo(
    () => [
      { main: 'The wait is over,', secondary: 'Your package is here!' },
      { main: 'Exciting News!', secondary: 'Check out our latest offers.' },
    ],
    []
  );

  const [randomText, setRandomText] = useState(titles[0]);

  useEffect(() => {
    const getRandomTitle = () => {
      const randomIndex = Math.floor(Math.random() * titles.length);
      setRandomText(titles[randomIndex]);
    };

    getRandomTitle();

    const intervalId = setInterval(getRandomTitle, 1500);

    return () => clearInterval(intervalId);
  }, [titles]);
  return (
    <section className='main main__2  '>
      <div className='w-11  md:w-11 lg:w-11 xl:w-10 m-auto '>
        <div className='grid grid__6__bg '>
          <div className='col-12  md:col-8 lg:col-8  xl:col-8  col__6__bg -mt-6 lg:mt-3   '>
            <div className='grid inner__gride  col-5 md:w-7 lg:w-6  xl:w-5 w-full m-auto px-3  '>
              <div className='col-3 md:col-3 lg:col-3 xl:col-3    mt-3 '>
                <div>
                  <div className=' '>
                    <img
                      src={badgeImage}
                      alt=''
                      className='badgeImageStyle2 '
                      loading='lazy'
                    />
                  </div>
                </div>
              </div>

              <div className='col-9 md:col-9 lg:col-9 xl:col-9 '>
                <div className='title2'>
                  <p className='titleStyle2 -mb-2'>{randomText.main}</p>
                  <small className='paragraphStyle2'>
                    {randomText.secondary}
                  </small>
                </div>
              </div>
              <div className=' tt m-auto '>
                <img
                  src={starPImage}
                  alt=''
                  className='starImage  '
                  loading='lazy'
                />
              </div>
            </div>
          </div>

          <div className='col-12 md:col-4 lg:col-4 xl:col-4 '>
            <div className='img___2 '>
              <img src={womanBgImage} alt='' className='img_2' loading='lazy' />

              <div className=' text-center'>
                <img
                  src={circleVectorImage}
                  alt=''
                  className='circleImage2 '
                  loading='lazy'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
