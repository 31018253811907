import React from 'react';
import Modal from 'react-modal';

const ModalComponent = ({ isOpen, onRequestClose, iframeSrc, style }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Movaex Website'
      style={style}
    >
      <iframe
        title='Movaex Website'
        src={iframeSrc}
        width='100%'
        height='100%'
        frameBorder='0'
        allowFullScreen
      ></iframe>
    </Modal>
  );
};

export default ModalComponent;
