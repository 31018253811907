import { Divider } from 'primereact/divider';
import '../styles/partner.css';
import kierianLogo from '../assets/kierian_technologies_logo.jpeg';
import Herosection from '../components/shareCoponent/heroSection';

export default function Partner() {
  return (
    <div className='partner___main'>
      {/* hero section component starts here */}
      <Herosection title='Partners' />
      {/* hero section component ends here */}

      <div className='w-11 md:w-10 lg:w-9 xl:w-7 m-auto'>
        <div className='grid partner__grid'>
          <div className='col-12 md:col-12 lg:col-4 xl:col-4'>
            <div className='image'>
              <img
                src={kierianLogo}
                alt='Kierian_logo'
                className='partner__image'
              />
            </div>
          </div>

          <div className='col-12 md:col-12 lg:col-8 xl:col-8'>
            <p className='partner__paragraph'>
              {' '}
              Kierian Technologies is a top Nigerian consultancy and IT
              Development firm. Established in 2017, they have helped many tech
              start-ups bootstrap their creative and innovative ideas to the
              production stage in the shortest period of time.
            </p>
          </div>

          <Divider />
        </div>
      </div>
    </div>
  );
}
