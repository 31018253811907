import ThirdSection from './../components/ThirdSection';
import ForthSection from './../components/forthSection';
import SixthSection from './../components/sixthComponent';
import FiftSection from './../components/FifthSection';
import SecondSection from './../components/secondSection';
import FirstSection from './../components/FirstSection';

function Index() {
  return (
    <section className='main'>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
       <ForthSection />
      <FiftSection />
      <SixthSection />
    </section>
  );
}

export default Index;
