import React from 'react';
import CookieConsent from 'react-cookie-consent';
import '../styles/CookieConsentComponent.css';

const CookieConsentComponent = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Accept'
      cookieName='myCookieConsent'
      style={{ background: '#e44540' }}
      buttonStyle={{
        color: '#000',
        fontSize: '15px',
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
      expires={365}
    >
      <p className='cookie__paragraph text-center'>
        {' '}
        This website uses cookies to enhance the user experience.
      </p>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
