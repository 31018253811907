import '../styles/AboutUs.css';
import playstoreImage from '../assets/playstore.png';
import applestoreImage from '../assets/applestore.png';
import badgeImage from '../assets/mova.png';
import ShareComponent from '../components/shareCoponent/shareComponent';
import CompanyObjective from '../components/shareCoponent/companyObjective';
import Herosection from '../components/shareCoponent/heroSection';
import MovaexImage from '../assets/movaex__hero__bg.jpg';

export default function AboutUs() {
  return (
    <div className='about__us__main'>
      {/* hero section component starts here */}
      <Herosection title='About Us' />
      {/* hero section component ends here */}

      <section className='about__grid__section' data-aos='fade-down'>
        <div className='grid  w-full md:w-11 lg:w-full xl:w-10 m-auto'>
          <div
            className='col-12 md:col-12 lg:col-6 xl:col-6 mt-5 md:pr-4 lg:pr-4 xl:pr-6'
            data-aos='zoom-out-right'
          >
            <div className='image__side__style'>
              <img
                src={MovaexImage}
                alt=''
                className=' img__side__style'
              />

              <div className=''>
                <div className='grid  img__side__style__inner py-1  p-2 md:px-3 w-9 md:w-7 lg:w-7 xl:w-6'>
                  <div className='col-7 md:col-8 lg:col-8 xl:col-8 mt-3 py-0'>
                    <p className='img__side__style__inner__p'>200</p>
                    <p className='img__side__style__inner___p'>
                      Certisfied clients
                    </p>
                  </div>

                  <div className='col-5 md:col-4  lg:col-4 xl:col-4 mt-3 py-0 '>
                    <div className='imgh'>
                      <img
                        src={badgeImage}
                        alt='badge__about  '
                        className='badge__about'
                        loading='lazy'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className='col-12 md:col-12 lg:col-6 xl:col-6 md:mt-8   lg:mt-5 xl:mt-5'
            data-aos='zoom-out-left'
          >
            <small className='about__sub__title '>ABOUT THE COMPANY</small>
            <h4 className=' about__sub__title2'>
              Movaex have harnessed the power of technology to create a
              seamless, <span></span>
              <span className='about__sub__title2__span'>
                on-demand delivery experience
              </span>
            </h4>

            <p className='about__sub__title2__paragraph'>
              At Movaex, we redefine the standards of dispatch logistics with an
              unwavering commitment to reliability, speed, and efficient
              customer support. Our mission is to not just meet but exceed your
              expectations at every turn.
            </p>

            <div className=' grid   '>
              <div className='col-6 grid1 md:col-4 lg:col-5 xl:col-4'>
                <a
                  href='https://play.google.com/store/apps/details?id=com.movaex.customer'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={playstoreImage} alt='' className='imgStyle1' />
                </a>
              </div>

              <div className='col-6 grid1 md:col-4 lg:col-5 xl:col-4  '>
                <a
                  href='https://app.movaex.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={applestoreImage} alt='' className='imgStyle1' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* share component starts AppHeader */}

      <ShareComponent />
      {/* share component ends AppHeader */}

      <div className='section second__about__grid p-3 md:p-4 lg:p-5 xl:p-6'>
        <div className='grid w-11 md:w-11 lg:w-10 xl:w-10 m-auto mt-3 '>
          <div className='col-12 md:col-6 lg:col-6'>
            <h5 className='second__about__sub__title'>Our MISSION</h5>
            <p className=' second__about__sub__paragraph'>
              Empower businesses and individuals through seamless and reliable
              dispatch logistics, leveraging cutting-edge technology, a
              dedicated team, and a commitment to unparalleled customer service.
            </p>
          </div>

          <div className='col-12 md:col-6 lg:col-6'>
            <h5 className='second__about__sub__title'>Our VISION</h5>
            <p className=' second__about__sub__paragraph'>
              To be the leading force in redefining dispatch logistics,
              pioneering innovative solutions, and consistently exceeding
              customer expectations.
            </p>
          </div>
        </div>
      </div>

      {/* share component starts AppHeader */}
      <CompanyObjective />
      {/* share component ends AppHeader */}
    </div>
  );
}
