import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import AppHeader from './components/AppHeader';
import FooterSection from './components/FooterSection';
import Index from './pages';
import AboutUs from './pages/aboutUs';
import Faqs from './pages/faqs';
import ContactUs from './pages/contactUs';
import Privacy from './pages/privacy';
import Support from './pages/support';
import Userdata from './pages/userdata';
import 'aos/dist/aos.css';

import AOS from 'aos';
import CookiePolicy from './pages/cookiePolicy';
import TermAndConditions from './pages/termAndCondition';
import Career from './pages/career';
import Partner from './pages/partner';
import Ourapp from './pages/ourapp';
import CookieConsentComponent from './components/CookieConsentComponent';
import Page404 from './pages/page404';
import WhatsAppButton from './components/WhatsAppButton';
import AdsPage from './pages/AdsPage';

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.gtag('event', 'page_view', {
      send_to: 'AW-16508243396/2DU4COCq9Z4ZEMSb3789',
    });
  }, []);

  return (
    <Router>
      <AppHeader />
      <Routes>
        <Route path='/' element={<Index />} exact />
        <Route path='/about' element={<AboutUs />} exact />
        <Route path='/faq' element={<Faqs />} exact />
        <Route path='/contact' element={<ContactUs />} exact />
        <Route path='/privacy' element={<Privacy />} exact />
        <Route path='/support' element={<Support />} exact />
        <Route path='/userdata' element={<Userdata />} exact />
        <Route path='/cookie-policy' element={<CookiePolicy />} exact />
        <Route path='/terms-of-service' element={<TermAndConditions />} exact />
        <Route path='/career' element={<Career />} exact />
        <Route path='/partners' element={<Partner />} exact />
        <Route path='/ourapp' element={<Ourapp />} exact />
        <Route path='/adpage' element={<AdsPage />} exact />
        <Route path='*' element={<Page404 />} />
      </Routes>
      <FooterSection />
      <WhatsAppButton />
      <CookieConsentComponent />
    </Router>
  );
}

export default App;
