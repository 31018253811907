import playstoreImage from '../assets/play.png';
import applestoreImage from '../assets/apple.png';
import '../styles/FifthSection.css';
import movaPhoneImage from '../assets/MovaPhone.png';
import circleVectorImage from '../assets/circleVector.svg';

export default function FiftSection() {
  return (
    <div
      className='w-11  mb-1 lg:w-10 xl:w-9 md:w-10 m-auto  md:mb-2 mainStyle5'
      data-aos='fade-down'
    >
      <div className='grid '>
        <div className='col-12  md:col-6   lg:col-6   xl:col-6  pt-0 pl-0 pb-0 '>
          <section className='sectionStyle5'>
            <h3 className='headerStylew5'>
              Ready to experience the future of logistics?
            </h3>
            <p className='paragraph5'>
              Join Movaex today and discover the convenience of on-demand,
              door-to-door delivery at your fingertips.
            </p>

            {/* <small className='smallText5'>For people in Enugu, Nigeria.</small> */}
            <div className=' grid lg:-ml-2 xl:-ml-2 mt-1'>
              <div className='col-6 md:col-6 lg:col-5 xl:col-4'>
                <a
                  href='https://app.movaex.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={applestoreImage}
                    alt=''
                    loading='lazy'
                    className='imageStyle5'
                  />
                </a>
              </div>
              <div className='col-6 md:col-6 lg:col-5 xl:col-4 '>
                <a
                  href='https://play.google.com/store/apps/details?id=com.movaex.customer'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={playstoreImage}
                    alt=''
                    loading='lazy'
                    className='imageStyle5'
                  />
                </a>
              </div>
            </div>
            <div className=''>
              <img
                src={circleVectorImage}
                alt=''
                className='circleImageStyle5'
                loading='lazy'
              />
            </div>
          </section>
        </div>

        <div className='col-12 md:col-offset-1 md:col-4  md:pb-0 '>
          <section>
            <img src={movaPhoneImage} alt='' className='movaImageStyle5' />
          </section>
        </div>
      </div>
    </div>
  );
}
