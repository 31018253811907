import '../styles/Faqs.css';

import { Accordion, AccordionTab } from 'primereact/accordion';

// import movaPhoneImage from '../assets/MovaPhone.png';
import ShareComponent from '../components/shareCoponent/shareComponent';
import Herosection from '../components/shareCoponent/heroSection';

export default function Faqs() {
  const faqsData = [
    {
      question: 'How do I book a delivery with Movaex?',
      answer:
        'Booking a delivery with us is quick and easy. Simply download our mobile app, create an account, and follow the intuitive steps to schedule your delivery. You can also contact our customer support for assistance',
    },
    {
      question: 'What sets Movaex apart from other logistics companies?',
      answer:
        'Movaex stands out for its unwavering commitment to reliability and speed. We prioritize efficient deliveries, backed by cutting-edge technology and a dedicated team that ensures your packages reach their destination on time',
    },
    {
      question: 'Can I track my package in real-time?',
      answer:
        'Yes, you can! Our innovative mobile app allows you to track your package in real-time. From the moment it’s picked up to its final destination, you’ll receive updates on its status, ensuring complete transparency throughout the delivery process.',
    },

    {
      question: 'What areas do you serve?',
      answer:
        'Movaex provides dispatch logistics services across Enugu, Lagos and Abuja. We are continuously expanding our reach to better serve our customers.',
    },

    {
      question: 'How does Movaex ensure the security of my shipments?',
      answer:
        'We take the security of your shipments seriously. Our team follows stringent protocols to ensure the safe and secure handling of packages. Additionally, our mobile app provides real-time tracking, giving you peace of mind throughout the entire delivery journey.',
    },

    {
      question: 'What if I need to change or cancel my delivery?',
      answer:
        'You can make changes or cancel your delivery through our mobile app or by contacting our customer support. Please note that there may be specific timelines for modifications, so it’s best to reach out to us as soon as possible.',
    },

    {
      question: 'How can I reach customer support?',
      answer:
        'Our customer support team is available 24/7. You can reach us by phone at +23413306085 or through the <a href="/contact" class="contact____link">Contact Us</a> feature in our mobile app. We’re here to assist you with any inquiries or concerns.',
    },
    {
      question:
        'Does Movaex offer customized logistics solutions for businesses?',
      answer:
        'Absolutely! We understand that businesses have unique logistics needs. Contact our business solutions team to discuss customized dispatch options tailored to your specific requirements.',
    },
    {
      question: 'Are there any hidden fees in your services?',
      answer:
        'Transparency is our priority. Our pricing is upfront, and there are no hidden fees. The cost of your delivery is clearly communicated during the booking process, so you know exactly what to expect.',
    },
    {
      question: 'How do I provide feedback about my experience with Movaex?',
      answer:
        'We welcome your feedback! You can submit your comments, suggestions, or concerns through our mobile app or by contacting our customer support. Your input helps us continually improve our services.',
    },
  ];

  return (
    <div className='faqs__main'>
      {/* hero section component starts here */}
      <Herosection title='Our FAQ' />
      {/* hero section component ends here */}

      <section className='faqs__grid__section '>
        <div className='mt-3 w-11 md:w-10 lg:w-9 xl:w-9 m-auto'>
          <Accordion activeIndex={0}>
            {faqsData.map((faq, index) => (
              <AccordionTab
                header={faq.question}
                className='____faqs'
                key={index}
              >
                <p
                  className='m-0 faqs__sub__paragraph'
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              </AccordionTab>
            ))}
          </Accordion>
        </div>
      </section>

      {/* share component starts AppHeader */}
      <ShareComponent />
      {/* share component ends AppHeader */}
    </div>
  );
}
