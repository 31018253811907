import React from 'react';
import '../../styles/heroSection.css';
const Herosection = (props) => {
  const { title } = props;

  return (
    <section className='hero__section__header py-4 text-center'>
      <h2 className='Hero__title -mt-1'>{title}</h2>
    </section>
  );
};

export default Herosection;
