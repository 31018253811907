import React from 'react';
import Herosection from '../components/shareCoponent/heroSection';
import '../styles/userdata.css';
import { Divider } from 'primereact/divider';
// import { InputText } from 'primereact/inputtext';
// import { Button } from 'primereact/button';
// import { Dialog } from 'primereact/dialog';
// import { InputNumber } from 'primereact/inputnumber';
// import { Toast } from 'primereact/toast';
// import axios from 'axios';

export default function Userdata() {
  // const [email, setEmail] = useState('');
  // const [visible, setVisible] = useState(false);
  // const [otp, setOtp] = useState('');
  // const toast = useRef(null);

  // const handleVerify = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const expirationTime = new Date();
  //     expirationTime.setHours(expirationTime.getHours() + 1);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_MOVAEX_API_URL}send-otp`,
  //       {
  //         title: 'send otp',
  //         email,
  //         expireAt: expirationTime,
  //       }
  //     );
  //     const { value: responseOtp, email: responseEmail } = response.data.otp;
  //     setOtp(responseOtp);
  //     setEmail(responseEmail);
  //     setVisible(true);
  //   } catch (error) {
  //     const errorMessage =
  //       error?.response?.data?.message || 'An error occurred';
  //     showToast('error', errorMessage);
  //   }
  // };

  // const handleUserUpdate = async () => {
  //   try {
  //     await axios.post('http://localhost:10090/delete-user', {
  //       email,
  //       code: otp,
  //     });
  //     showToast('success', 'User data updated successfully');
  //   } catch (error) {
  //     const errorMessage =
  //       error?.response?.data?.message || 'An error occurred';
  //     showToast('error', errorMessage);
  //   }
  // };

  // const showToast = (severity, detail) => {
  //   toast.current.show({
  //     severity,
  //     summary: severity === 'success' ? 'Success' : 'Error',
  //     detail,
  //   });
  // };

  return (
    <div className='main___userdata'>
      {/* <Toast ref={toast} /> */}

      {/* hero section component starts here */}
      <Herosection title='User Data Management for Movaex' />
      {/* hero section component ends here */}

      <div className='w-11 md:w-11 lg:w-10 xl:w-9 m-auto'>
        <div className='userdata__first__section'>
          <h5 className='-mb-1 user____header__h5'>Contact Information</h5>

          <p className='userdata___paragraph'>
            If you need assistance or have any questions, you can reach our
            support team through the following channels:
          </p>

          <ul className='list-disc  '>
            <li className='userdata___paragraph__li'>
              Email:{' '}
              <a href='mailto:support@movaex.com' className='a___a'>
                support@movaex.com
              </a>
            </li>
          </ul>
        </div>

        {/* <div className='w-7 m-auto email___from'>
          <div className='row'>
            <div className='col-12'>
              <label
                htmlFor='email'
                className='block  text-sm font-medium mb-2'
              >
                Email
              </label>
              <InputText
                id='email'
                type='text'
                placeholder='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='w-full mb-2 outline-none login__border_style'
              />
            </div>

            <div className='col-12'>
              <Button
                label='Verify'
                onClick={handleVerify}
                className='w-full button__style'
              />
            </div>
          </div>
        </div> */}

        {/* <div className='card flex justify-content-center'>
          <Dialog
            header='OTP Form'
            visible={visible}
            style={{ width: '50vw' }}
            onHide={() => setVisible(false)}
          >
            <div className='row'>
              <div className='col-12'>
                <label
                  htmlFor='email'
                  className='block  text-sm font-medium mb-2'
                >
                  OTP (One Time Password)
                </label>
                <InputNumber
                  id='number-input'
                  placeholder='OTP (One Time Password)'
                  className='w-full'
                  value={otp}
                  disabled
                  useGrouping={false}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>

              <div className='col-12'>
                <Button
                  label='Delete'
                  icon='pi pi-trash'
                  className='w-full button__style'
                  onClick={handleUserUpdate}
                />
              </div>
            </div>
          </Dialog>
        </div> */}

        <Divider />
        <div className='first__section mt-6'>
          <p className='support___paragraph text-center'>
            If you have any other inquiries or feedback, feel free to contact
            us. We are here to help!
          </p>
        </div>
      </div>
    </div>
  );
}
