import '../../styles/shareComponent.css';
import accelerateImage from '../../assets/accelerate.svg';
import creativityImage from '../../assets/creativity.svg';
import cellPhoneImage from '../../assets/cellphone.svg';

export default function ShareComponent() {
  const datas = [
    {
      image: accelerateImage,
      title: 'Unmatched Speed',
      paragrph:
        ' In the world of dispatch logistics, time is of the essence. That’s why we pride ourselves on our unmatched speed in handling and delivering packages. From the moment you choose Movaex, you can expect a swift and seamless process that keeps pace with your business demands.Innovative Mobile App',
    },
    {
      image: creativityImage,

      title: 'Our Commitment to Reliability',
      paragrph:
        ' We understand the importance of dependable logistics in today’s fast-paced world. With a focus on precision and efficiency, we guarantee that your shipments reach their destination securely and on schedule. Our team is driven by a passion for excellence, ensuring that you can trust us with your most critical deliveries.',
    },
    {
      image: cellPhoneImage,

      title: 'Innovative Mobile App',
      paragrph:
        'Experience the future of dispatch logistics with our cutting-edge mobile app. Our user-friendly platform allows you to effortlessly book our delivery officers, track packages in real-time, and receive instant updates on the status of your shipments. We believe in putting the power of logistics in the palm of your hand, making your experience with us efficient and transparent.',
    },
  ];
  return (
    <section
      className='w-11 md:w-11 lg:w-11 xl:w-9 m-auto  ___main '
      data-aos='fade-down'
    >
      <div className='share__component__title text-center'>
        <h2 className='share__component__title__h2'>Our Goodness</h2>
        <p className='share__component__title__p'>What Make Us Special</p>
      </div>

      <div className='grid'>
        {datas.map((data, index) => (
          <div
            className='col-12 md:col-6 lg:col-4 xl:col-4 text-center   '
            key={index}
            data-aos='zoom-in'
          >
            <div className='___bg'>
              <div className='text-center'>
                <img
                  src={data.image}
                  alt=''
                  className='share__component__title__subtitle__image'
                />
              </div>
              <h5 className='share__component__title__subtitle'>
                {data.title}
              </h5>
              <p className='share__component__title__paragraph'>
                {data.paragrph}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
