import vectorImage from '../assets/Vector.png';
import vectorImage1 from '../assets/v1.png';
import vectorImage2 from '../assets/v2.png';
import vectorManImage from '../assets/VectorMan.png';
import securityImageVector from '../assets/securityVector.png';
import starVectorImage from '../assets/starVector.png';
import '../styles/sixthComponent.css';

import badgeImage from '../assets/v.png';
import badgeImage1 from '../assets/Ve.png';
export default function SixthSection() {
  const data = [
    {
      badgeImage: badgeImage,
      vectorImage: vectorImage,
      title: 'Live Package Tracking',
      description:
        'Watch your items go from A-to-B live on the app! You can also share the tracking information with your recipient.',
    },
    {
      badgeImage: badgeImage1,
      vectorImage: vectorImage1,
      title: 'Scheduled Pickup',
      description:
        'Need to have your item picked up and delivered at a particular time? You can schedule package pickup and we’d be there for you.',
    },
    {
      badgeImage: badgeImage,
      vectorImage: vectorImage2,
      title: 'We cater to all sizes.',
      description:
        'With us, size doesn’t matter. If a bike can’t carry it, we have delivery vans that can move whatever you have to move.',
    },

    {
      badgeImage: badgeImage1,
      vectorImage: vectorManImage,
      title: 'Fast Pilot Matching.',
      description:
        'Request and get matched to a pilot in minutes. Every package is priority 1',
    },
    {
      badgeImage: badgeImage,
      vectorImage: securityImageVector,
      title: 'Sure Security',
      description: 'Your items are important, and we treat them as such. ',
    },
    {
      badgeImage: badgeImage1,
      vectorImage: starVectorImage,
      title: 'Reliable Service',
      description:
        'Count on us for on-time deliveries, every time. Never experience a downtime or absence of delivery pilot.',
    },
  ];

  return (
    <div
      className='w-10  mb-1 md:w-10 lg:w-10 xl:w-9 m-auto mt-8 md:mb-2 '
      data-aos='fade-up'
    >
      <div className='header6 text-center'>
        <h2 className='headerStyle6'>
          The Movaex mobile app puts every information about your package
          delivery in your palm
        </h2>
      </div>
      <div className='grid'>
        {data.map((item, index) => (
          <div
            className='col-12  md:col-6 lg:col-5 xl:col-4'
            key={index}
            data-aos='zoom-in'
          >
            <div className='grid'>
              <div className='col-3 md:col-3 lg:col-3 xl:col-3 mt-5 lg:-mr-1 xl:-mr-1'>
                <div>
                  <div className='  bg6 mt-2'>
                    <img
                      src={item.badgeImage}
                      alt=''
                      className='badgeImageStyle6'
                      loading='lazy'
                    />
                    <img
                      src={item.vectorImage}
                      alt=''
                      className='imageStyle6 '
                      loading='lazy'
                    />
                  </div>
                </div>
              </div>

              <div className='col-9 md:col-9 lg:col-9 xl:col-9'>
                <div className='title6'>
                  <h5 className='titleStyle6'>{item.title}</h5>
                  <p className='-mt-4 paragraphStyle6'>{item.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
