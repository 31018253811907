import Herosection from '../components/shareCoponent/heroSection';
import '../styles/support.css';
import { Divider } from 'primereact/divider';

export default function Support() {
  return (
    <div className='main___privacy'>
      {/* hero section component starts here */}
      <Herosection title='Support for Movaex' />
      {/* hero section component ends here */}

      <div className='w-11 md:w-11 lg:w-10 xl:w-9 m-auto'>
        <div className='first__section'>
          <h6 className='-mb-1 '>Contact Information</h6>

          <p className='support___paragraph'>
            If you need assistance or have any questions, you can reach our
            support team through the following channels:
          </p>

          <ul className='list-disc  '>
            <li className='privacy___paragraph__li'>
              Email:{' '}
              <a href='mailto:support@movaex.com' className='a___a'>
                support@movaex.com
              </a>
            </li>
          </ul>
        </div>

        <div className='first__section'>
          <h6 className='-mb-2'>Frequently Asked Questions (FAQ)</h6>

          <p className='privacy___paragraph'>
            Check our{' '}
            <span className='support__span'>
              {' '}
              <a href='/faqs' className='a___a'>
                {' '}
                FAQ page{' '}
              </a>{' '}
            </span>{' '}
            for answers to common questions and troubleshooting tips.
          </p>
        </div>

        <div className='first__section'>
          <h6 className='-mb-1'>Submit a Support Ticket</h6>

          <p className='support___paragraph'>
            If you couldn't find a solution in our FAQ, you can submit a support
            ticket through our{' '}
            <span className='support__span'>
              {' '}
              <a href='/contact' className='a___a'>
                online support form.
              </a>
            </span>
          </p>
        </div>

        <Divider />
        <div className='first__section mt-6'>
          <p className='support___paragraph text-center'>
            If you have any other inquiries or feedback, feel free to contact
            us. We are here to help!
          </p>
        </div>
      </div>
    </div>
  );
}
