import Herosection from '../components/shareCoponent/heroSection';
import '../styles/privacy.css';
import { Divider } from 'primereact/divider';
export default function Privacy() {
  return (
    <div className='main___privacy'>
      {/* hero section component starts here */}
      <Herosection title='Privacy Policy for Movaex' />
      {/* hero section component ends here */}

      <div className='w-11 md:w-11 lg:w-10 xl:w-9 m-auto'>
        <div className='text-center'>
          <h6 className='privacy____sub__header'>
            Last Updated: Mon Dec 04 2023 00:00:00 (Coordinated Universal Time)
          </h6>
        </div>
        <Divider />
        <div className='first__section'>
          <h5 className='-mb-1 privacy__header__h4'>1. Introduction</h5>

          <p className='privacy___paragraph'>
            Welcome to Movaex ("we," "our," or "us"). This Privacy Policy
            outlines how we collect, use, disclose, and safeguard your personal
            information when you use our mobile application and services. By
            accessing or using our app, you agree to the terms outlined in this
            policy.
          </p>
        </div>

        <div className='first__section'>
          <h5 className='-mb-2 privacy__header__h4'>Information We Collect</h5>
          <div className=' '>
            <h5 className='-mb-4 privacy___paragraph__h5'>
              Personal Information:
            </h5>
            <ul className='list-disc  '>
              <li className='privacy___paragraph__li'>
                When you register an account, we may collect your name, email
                address, phone number, and delivery address.
              </li>
              <li className='privacy___paragraph__li'>
                Payment information (credit card details) will be collected when
                making a purchase through the app.
              </li>
            </ul>
          </div>

          <div className=' '>
            <h5 className='-mb-1 privacy___paragraph__h5'>
              Usage Information:
            </h5>
            <p className='privacy___paragraph'>
              We collect information about your interactions with the app, such
              as pages viewed, features used, and the time spent on the app.
            </p>
          </div>

          <div className=' '>
            <h5 className='-mb-1 privacy___paragraph__h5'>
              Device Information:
            </h5>
            <p className='privacy___paragraph'>
              Information about your device, including device type, operating
              system, and unique device identifiers.
            </p>
          </div>
          <div className=' '>
            <h5 className='-mb-4 privacy___paragraph__h5'>
              How We Use Your Information
            </h5>
            <ul className='list-disc  '>
              <li className='privacy___paragraph__li'>
                To provide and improve our services.
              </li>
              <li className='privacy___paragraph__li'>
                To process and fulfill delivery orders.
              </li>

              <li className='privacy___paragraph__li'>
                To personalize your experience and enhance customer support.
              </li>

              <li className='privacy___paragraph__li'>
                To send updates, promotions, and other relevant information.
              </li>
            </ul>
          </div>
        </div>

        <div className='first__section'>
          <h5 className='-mb-1 privacy__header__h4'>2. Data Security</h5>

          <p className='privacy___paragraph'>
            We employ industry-standard security measures to protect your
            information. However, no method of transmission over the internet or
            electronic storage is completely secure. Therefore, we cannot
            guarantee absolute security.
          </p>
        </div>

        <div className='first__section'>
          <h5 className='-mb-1 privacy__header__h4'>
            3. Sharing of Information
          </h5>

          <p className='privacy___paragraph'>
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties. We may share your information with
            trusted third parties who assist us in operating our app and
            providing services, always under confidentiality agreements.
          </p>
        </div>

        <div className='first__section'>
          <h5 className='-mb-1 privacy__header__h4'>
            4. Cookies and Tracking Technologies
          </h5>

          <p className='privacy___paragraph'>
            We use cookies and similar technologies to enhance your experience,
            analyze usage patterns, and improve our services. You can manage
            your cookie preferences through your browser settings.
          </p>
        </div>

        <div className='first__section'>
          <h5 className='-mb-1 privacy__header__h4'>5. Your Choices</h5>

          <p className='privacy___paragraph'>
            You can choose not to provide certain information, but this may
            limit your ability to use certain features of the app. You can also
            opt-out of receiving promotional emails.
          </p>
        </div>

        <div className='first__section'>
          <h5 className='-mb-1 privacy__header__h4'>6. Children's Privacy</h5>

          <p className='privacy___paragraph'>
            Our services are not intended for individuals under the age of 13.
            We do not knowingly collect personal information from children. If
            you believe a child has provided us with personal information,
            please contact us.
          </p>
        </div>

        <div className='first__section'>
          <h5 className='-mb-1 privacy__header__h4'>
            Changes to This Privacy Policy
          </h5>

          <p className='privacy___paragraph'>
            We reserve the right to update this Privacy Policy. Any changes will
            be effective upon posting the revised policy on the app. We
            encourage you to review this page periodically for any updates.
          </p>
        </div>
        <Divider />
        <div className='first__section mt-6'>
          <p className='privacy___paragraph text-center'>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy, please contact us at{' '}
            <a href='mailto:support@movaex.com' className='a___a'>
              support@movaex.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
