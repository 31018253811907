import React from 'react';
import '../styles/page404.css'; 

export default function Page404() {
  return (
    <div className='page404'>
      <div className='page404__container'>
        <div className='page404__content'>
          <h1 className='page404___h1'>404</h1>
          <p className='page404___p'>Oops! Page not found.</p>
          <p className='page404___p'>
            It seems like the page you are looking for does not exist.
          </p>
          <a href='/' className='page404___a'>
            Go back to Home
          </a>
        </div>
      </div>
    </div>
  );
}
