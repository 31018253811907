import reliabilityImage from '../assets/Reliability.png';
import flexibilityImage from '../assets/Flexibility.png';
import convenienceImage from '../assets/Convenience.png';
import circleVectorImage from '../assets/circleVector.svg';
import '../styles/ThirdSection.css';

export default function ThirdSection() {
  return (
    <div
      className='w-11 fade-down mb-1 md:w-10 lg:w-11 xl:w-11 lg:mt-5  m-auto  md:mb-2 relative'
      data-aos='fade-down'
    >
      <div className=' text-center '>
        <h2 className='header__3 '>Why Choose Movaex? </h2>
        <p className='-mt-4  text-center w-full md:w-11 lg:w-10 xl:w-8 m-auto mb-8 paragraph3 '>
          Our mission is to empower businesses, legal professionals, food
          vendors, and individuals with the convenience and reliability they
          deserve.
        </p>
      </div>

      <div className='     subSection3 relative'>
        <div className='grid'>
          <div className='col-12 md:col-12 lg:col-6 mt-7' data-aos='fade-right'>
            <div className=' relative card3 '>
              <div className='image3 absolute  '>
                <img
                  src={reliabilityImage}
                  className='imageContainer3'
                  alt=''
                />
              </div>
              <h2 className='cardTitle3'>Reliability</h2>
              <p className='cardParagraph3'>
                {' '}
                When you need something delivered, you need it done right. With
                Movaex, you can count on us to deliver your items safely and on
                time, every time. Our team of dedicated drivers is committed to
                ensuring your parcels reach their destination with care.{' '}
              </p>
            </div>
          </div>

          <div className='col-12 md:col-12 lg:col-6 mt-7' data-aos='zoom-in'>
            <div className='  relative  card3'>
              <div className='image3 absolute '>
                <img
                  src={convenienceImage}
                  alt=''
                  className='imageContainer3'
                />
              </div>
              <h2 className='cardTitle3'>Convenience</h2>
              <p className='cardParagraph3'>
                {' '}
                Gone are the days of waiting in long lines or struggling to find
                a courier service that fits your schedule. Movaex is here to
                simplify your life. With our super app, you can request a
                delivery with just a few taps, and our drivers will be on their
                way.{' '}
              </p>
            </div>
          </div>

          <div className='col-12 md:col-12 lg:col-12 mt-7' data-aos='zoom-in'>
            <div className=' relative card3'>
              <div className='image3 absolute '>
                <img
                  src={flexibilityImage}
                  alt=''
                  className='imageContainer3'
                />
              </div>
              <h2 className='cardTitle3'>Flexibility</h2>
              <p className='cardParagraph3'>
                {' '}
                Whether you're a business looking to streamline your deliveries
                or an individual who needs to send a package across town, Movaex
                is the solution. Our platform accommodates a wide range of
                delivery needs, so you can always rely on us.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className=' text-center'>
          <img src={circleVectorImage} alt='' className='circleImage3' />
        </div>
      </div>
    </div>
  );
}
