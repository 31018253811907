import React from 'react';
import '../styles/WhatsAppButton.css';
import WhatsappImage from '../assets/Wh.png';
const WhatsAppButton = () => {
  const openWhatsApp = () => {
    const whatsappNumber = '2349068063045';
    const url = `https://wa.me/${whatsappNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div style={{}} className='whatsAppButton__style ' onClick={openWhatsApp}>
      <span className='tooltip'>Request or schedule a delivery.</span>

      <img
        className='whatsAppButton__image__style'
        src={WhatsappImage}
        alt='Movaex_logistic'
      />
    </div>
  );
};

export default WhatsAppButton;
